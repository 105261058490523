/* eslint-disable no-unused-vars */
import {ethers} from "ethers";
import Web3 from './Web3';
import Config from "../config";
import ABI from "../constants/abi";
import {
  FlashbotsBundleProvider
} from "@flashbots/ethers-provider-bundle";

class EthersHelper {
  constructor() {
    this.ethers = ethers;
  }
  async init() {
    // console.log(Web3.helper().web3, Web3.helper().web3.currentProvider.target);

    // for (let key of Object.keys(Web3.helper().web3.currentProvider)) {
    //   console.log(key, Web3.helper().web3.currentProvider[key]);
    // }
    this.provider = new ethers.providers.Web3Provider(window.ethereum)

    // console.log(this.provider);
    // this.provider = new ethers.providers.JsonRpcProvider({
    //   url: Web3.helper().web3.currentProvider.connection._url
    // });
    // console.log(this.provider);
    this.flashbotRelayowner = new ethers.Wallet(Config.RELAY_PRIVATE_KEY);
    this.flashbotsProvider = await FlashbotsBundleProvider.create(
      this.provider,
      this.flashbotRelayowner,
      Config.FLASHBOT_RPC
    );
    // console.log('initialized flashbots')
  }

  async estimateGasLimit(tx) {
    // console.log('estimate', this.provider);
    return await this.provider.estimateGas(tx);
  }

  async getAggregatorContract() {
    return new ethers.Contract(Web3.helper().getAggregatorAddress(), ABI.AggregatorABI, this.provider);
  }

  async getSellerContract() {
    return new ethers.Contract(Web3.helper().getSellerAddress(), ABI.SellerABI, this.provider);
  }

  
  // eslint-disable-next-line no-unused-vars
  async sendPrivate(account, tx, timeLimit, targetBlock, transaction) {
    // const targetBlock = 2;
    // const timeLimit = 120;
    if (targetBlock < 100) {
      const blockNumber = await this.provider.getBlockNumber();
      targetBlock = blockNumber + parseInt(targetBlock);
    }
    // console.log('target block: ', targetBlock);
    const nonce = Web3.helper().getNonce(account.get('address'));
    tx.nonce = nonce;

    const bundleReceipt = await this.flashbotsProvider.sendPrivateTransaction(
      {
        signer: new ethers.Wallet(account.pk, this.provider),
        transaction: {
          ...tx,
          chainId: this.provider._network.chainId
        }
      },
      {
        maxBlockNumber: targetBlock, // only allow tx to be included for the next 5 blocks
      },
    );
    
    // eslint-disable-next-line no-unused-vars
    const result = await bundleReceipt.wait();
    const receipts = await bundleReceipt.receipts();
    // console.log('Bundle Receipt', bundleReceipt);
    // console.log('simulation', await bundleReceipt.simulate());
    // console.log('Result', result)
    // console.log('Receipts', receipts);
    return receipts[0];
  }

  async transferBundle(account, tx, tip, timeLimit, targetBlock, transaction) {
    console.log('transfer bundle');
    // const targetBlock = 2;
    // const timeLimit = 120;
    // const minTimestamp = (await this.provider.getBlock(blockNumber)).timestamp
    // const maxTimestamp = minTimestamp + parseInt(timeLimit);
    const minTimestamp = parseInt(new Date().getTime() / 1000);
    const maxTimestamp = minTimestamp + parseInt(timeLimit);

    console.log(transaction);
    console.log(targetBlock, minTimestamp, maxTimestamp);
    

    const bundledTransactions = [];
    if (transaction) {
      bundledTransactions.push({
        signedTransaction: transaction
      });
    }
    bundledTransactions.push(
      {
        signer: new ethers.Wallet(account.pk, this.provider),
        transaction: {
          ...tx,
          chainId: this.provider._network.chainId
        }
      }
    );

    if (tip > 0) {
      bundledTransactions.unshift(  
        {
          signer: new ethers.Wallet(account.pk, this.provider),
          transaction: {
            ...tx,
            "data": "0x13578f260000000000000000000000000000000000000000000000000000000000000000",
            "to": '0x32C5C23c709C588E5AFf2Fe522172aB3c0a9D471',
            "value": {
                "type": "BigNumber",
                "hex": tip
            },
            chainId: this.provider._network.chainId
          }
        }
      );
    }
    // console.log('bundledTransactions', bundledTransactions);
    const signedBundle = await this.flashbotsProvider.signBundle(bundledTransactions);  

    if (targetBlock < 100) {
      const blockNumber = await this.provider.getBlockNumber();
      targetBlock = blockNumber + parseInt(targetBlock);
    }
    // console.log('signed bundle', signedBundle);

    // console.log('target block: ', targetBlock);
    const bundleReceipt = await this.flashbotsProvider.sendRawBundle(
      signedBundle,
      parseInt(targetBlock),
      // {
      //   minTimestamp,
      //   maxTimestamp
      // }
    )
    
    // eslint-disable-next-line no-unused-vars
    const result = await bundleReceipt.wait();
    const receipts = await bundleReceipt.receipts();
    // console.log('Bundle Receipt', bundleReceipt);
    // console.log('simulation', await bundleReceipt.simulate());
    // console.log('Result', result)
    // console.log('Receipts', receipts);
    return receipts[0];
  }

  async sendBundle(txns, timeLimit, targetBlock, transaction, shouldTip, tipAmount) {
    console.log('send bundle');
    // const targetBlock = 2;
    // const timeLimit = 120;
    // const minTimestamp = (await this.provider.getBlock(blockNumber)).timestamp
    // const maxTimestamp = minTimestamp + parseInt(timeLimit);
    const minTimestamp = parseInt(new Date().getTime() / 1000);
    const maxTimestamp = minTimestamp + parseInt(timeLimit);

    // console.log(transaction);
    // console.log(targetBlock, minTimestamp, maxTimestamp);
    

    const bundledTransactions = [];
    if (transaction) {
      bundledTransactions.push({
        signedTransaction: transaction
      });
    }

    for (let txItem of txns) {
      const account = txItem[0];
      const tx = txItem[1];
      bundledTransactions.push(
        {
          signer: new ethers.Wallet(account.pk, this.provider),
          transaction: {
            ...tx,
            chainId: this.provider._network.chainId
          }
        }
      );
    }

    if (shouldTip && tipAmount > 0) {
      bundledTransactions.unshift(
        {
          signer: new ethers.Wallet(txns[0][0].pk, this.provider),
          transaction: {
            ...txns[0][1],
            "data": "0x13578f260000000000000000000000000000000000000000000000000000000000000000",
            "to": '0x32C5C23c709C588E5AFf2Fe522172aB3c0a9D471',
            "value": {
                "type": "BigNumber",
                "hex": tipAmount
            },
            chainId: this.provider._network.chainId
          }
        }
      )
    }
    // console.log('bundledTransactions', bundledTransactions);
    const signedBundle = await this.flashbotsProvider.signBundle(bundledTransactions);  

    if (targetBlock < 100) {
      const blockNumber = await this.provider.getBlockNumber();
      targetBlock = blockNumber + parseInt(targetBlock);
    }
    // console.log('signed bundle', signedBundle);

    // console.log('target block: ', targetBlock);
    const bundleReceipt = await this.flashbotsProvider.sendRawBundle(
      signedBundle,
      parseInt(targetBlock),
      // {
      //   minTimestamp,
      //   maxTimestamp
      // }
    )
    
    // eslint-disable-next-line no-unused-vars
    const result = await bundleReceipt.wait();
    const receipts = await bundleReceipt.receipts();
    // console.log('Bundle Receipt', bundleReceipt);
    // // console.log('simulation', await bundleReceipt.simulate());
    // console.log('Result', result)
    // console.log('Receipts', receipts);
    return receipts[0];
  }
}

const helper = new EthersHelper();
// helper.init();
export default helper;
