import Web3Evm from './Web3Evm';
import Web3Tron from './Web3Tron';

let helper = Web3Evm;
// helper.init();
export default {
  helper: () => helper,
};

const mapDecimals = {
  tron: 6,
  evm: 18
}
export function defaultDecimals() {
  if (helper == Web3Tron) {
    return mapDecimals.tron;
  } else {
    return mapDecimals.evm;
  }
}
export function getWeb3() {
  return helper;
}
export function setWeb3(type) {
  if (type == 'tron') {
    helper = Web3Tron;
  } else {
    helper = Web3Evm;
  }
}